<template>
	<el-card class="box-card">
		<div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				{{parseInt(this.$route.query.type)|typename}}
			</h3>
		</div>
		<div style="width: 60%;">
		<el-form ref="form" :model="form" label-width="80px">
<!-- 			<el-form-item label="资讯类型">
				  <el-radio :disabled="parseInt(this.$route.query.type)==1?true:false" v-model="form.type" :label="1">图文类</el-radio>
				  <el-radio :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.type" :label="2">音视频类</el-radio>
			</el-form-item>	 -->

			<el-form-item label="课程分类">
			  <el-cascader
			   :disabled="parseInt(this.$route.query.type)==1?true:false" 
				v-model="form.cid"
			    :props="optionProps"
			    :options="options"
			    @change="setclass"
			    ref="cascader"
			  ></el-cascader>
			</el-form-item>
		<el-form-item label="名字">
		  <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.author_name"></el-input>
		</el-form-item>	
		<el-form-item label="简介">
		  <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.author_synopsis"></el-input>
		</el-form-item>	
		<el-form-item label="头像">
		  <el-upload
		   :disabled="parseInt(this.$route.query.type)==1?true:false" 
		    class="avatar-uploader"
		    :action="serverUrl"
			name='image'
		    :show-file-list="false"
		    :on-success="handleAvatarSuccess2"
			:headers="header" 
			:data="data"
		    :before-upload="beforeAvatarUpload">
		    <img v-if="form.author_avatar" :src="imgurl+form.author_avatar" class="avatar">
		    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		  </el-upload>
		  <!-- <el-upload class="avatar-uploader-editor" :action="serverUrl" name="image" :headers="header" :data="data" :show-file-list="false"
		  :on-success="uploadSuccess" :before-upload="be_up" :on-error="uploadError"> </el-upload> -->
		</el-form-item>
		  <el-form-item label="课程名称">
		    <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.name"></el-input>
		  </el-form-item>
		  <el-form-item label="课程标题">
		    <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.title"></el-input>
		  </el-form-item>
		  <el-form-item label="课程关键词">
		    <el-input placeholder="用于列表搜索" :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.keyword"></el-input>
		  </el-form-item>
		  
		<el-form-item label="课程封面">
		  <el-upload
		   :disabled="parseInt(this.$route.query.type)==1?true:false" 
		    class="avatar-uploader"
		    :action="serverUrl"
			name='image'
		    :show-file-list="false"
		    :on-success="handleAvatarSuccess"
			:headers="header" 
			:data="data"
		    :before-upload="beforeAvatarUpload">
		    <img v-if="form.thumbnail" :src="imgurl+ form.thumbnail" class="avatar">
		    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		  </el-upload>
		  <!-- <el-upload class="avatar-uploader-editor" :action="serverUrl" name="image" :headers="header" :data="data" :show-file-list="false"
		  :on-success="uploadSuccess" :before-upload="be_up" :on-error="uploadError"> </el-upload> -->
		</el-form-item>
			<el-form-item label="课程说明">
			  <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.course_description"></el-input>
			</el-form-item>
			<el-form-item label="是否收费">
			  <el-radio :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.ischarge" :label="0">免费 </el-radio>
			  <el-radio :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.ischarge" :label="1">VIP免费</el-radio>
			  <!-- <el-radio :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.ischarge" :label="2">收费</el-radio> -->
			  <el-form-item style="margin-top: 20px;" v-if="form.ischarge!=0" label="非VIP价格">
			    <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.price"></el-input>
			  </el-form-item>
			  <el-form-item style="margin-top: 20px;" v-if="form.ischarge!=0&&form.ischarge!=1" label="VIP价格">
			    <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.vip_price"></el-input>
			  </el-form-item>
			</el-form-item>
			
<!-- 			<el-form-item v-if="form.type==2" label="视频">
			  <el-upload
			    class="avatar-uploader"
			    :action="serverUrl"
				name='image'
			    :show-file-list="false"
			    :on-success="handleAvatarSuccess1"
				:headers="header" 
				:data="data"
			    :before-upload="beforeAvatarUpload">
			    <img v-if="form.video_info" :src="form.video_info" class="avatar">
			    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			  </el-upload>
			</el-form-item> -->
			<!-- <el-form-item v-if="form.type==2" label="音频">
			  <el-upload
			    class="avatar-uploader"
			    :action="serverUrl"
				name='image'
			    :show-file-list="false"
			    :on-success="handleAvatarSuccess2"
				:headers="header" 
				:data="data"
			    :before-upload="beforeAvatarUpload">
			    <img v-if="form.audio_info" :src="form.audio_info" class="avatar">
			    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			  </el-upload>
			</el-form-item> -->
		  <!-- <el-form-item  v-if="form.type==1" label="图文信息">
		        <div class="edit_container" style="height: 400px; position: relative;">
					<editor :content="content" @Editor="Editor"></editor>
				</div>	
		  </el-form-item> -->
		  <el-form-item v-if="parseInt(this.$route.query.type)!=1">
		    <el-button type="primary" @click="onSubmit">确定</el-button>
		    <!-- <el-button>取消</el-button> -->
		  </el-form-item>
		</el-form>
		</div>
	</el-card>
</template>	
<script>
	// editor
	import {baseURL} from "@/api/https.js";
	import editor from '../../components/editor/index.vue'
	import {getSystemsettings} from '@/api/getSystemsetting.js'
	import {
	  classifyList,
	  CourseAdd,
	  addNewsCategory,
	  findNewsCateDetail,
	  delNewsCate,
	  updataImg,
	  newsSave
	} from "@/api/api.js";
	// import UpImg from "@/components/upImg.vue";
	export default {
		components: {
			editor,
			 // UpImg 
		},
		data() {
			return {
				header:{AdminToken:localStorage.getItem('admin_token')},
				serverUrl:baseURL+'/backend/uploadsfile/upload_image', //上传的图片服务器地址
				data:{
					type:'addnews'
				},
				type:'',
				imageUrl:'',
				optionProps: {
				  value: "id",
				  label: "name",
				  children: "children",
				  checkStrictly: true,
				},
				options:[],
				content:'',
				form: {
				// radio:1,
				name: '',
				cid:'',
				type:1,
				title:'',
				keyword:'',
				thumbnail:'',
				// content:'',
				author_avatar:'',
				author_name:'',
				author_synopsis:'',
				course_description:'',
				ischarge:0,
				price:'',
				vip_price:'',
				imgurl:''
				
				}
			}
		},

		mounted() {
			console.log(baseURL,'sssssssssssss')
			
			getSystemsettings.then(res=>{
				this.imgurl=res
			})
			this.getlist()
			console.log(this.$route.query.type)
			if(this.$route.query.row){
				this.form=JSON.parse(this.$route.query.row)
				this.form.id=JSON.parse(this.$route.query.row).id
				this.content=JSON.parse(this.$route.query.row).content
				console.log(this.content)
			}
			// this.type=this.$route.query.type
			
		},
		filters:{
			typename(val){
			    let chargename = '';
			    switch (val) {
			        case 0:
			            chargename = '添加课程';
			            break;
			        case 1:
			            chargename = '课程详情';
			            break;
			        case 2:
			            chargename = '编辑课程';
			            break;
			
			    }
			    return chargename;
			},
		},
		methods: {
			
			handleAvatarSuccess(res,file){
				
				console.log(res,file)
				this.imageUrl=res.data.imgurl
				this.form.thumbnail=res.data.imgurl
			},
			handleAvatarSuccess1(){},
			handleAvatarSuccess2(res,file){
				this.form.author_avatar=res.data.imgurl
			},
			beforeAvatarUpload(file){
				console.log(file)
			},
			Editor(e){
				// console.log(e)
				var arr=[]
				arr.push(e)
				this.form.content_info=arr
			},
			setclass(e){
				console.log(e)
				console.log(this.form.cid)
				this.form.cid=this.form.cid[this.form.cid.length-1]
				console.log(this.options,'this.options')
				for(var i=0; i<this.options.length; i++){
					for(var t=0; t<this.options[i].children.length;t++){
						if(this.form.cid==this.options[i].children[t].id){
							this.form.type=this.options[i].children[t].type
						}
					}	
					// console.log(this.form.cid,this.options[i].id)
					
				}
				console.log(this.form.type)
				// if(this.form.cid==17){
				// 	this.form.type=2
				// }else{
				// 	this.form.type=1
				// }
				// for(var i=0;i<this.form.cid.length; i++){
					
				// }
			},
			onSubmit() {
				console.log(this.form)
				console.log('submit!');
				// console.log(editor)
				this.newsSave()
			},
			goBack() {
				console.log('go back');
				this.$router.back(-1)
			},
			// 添加
			async newsSave() {
				console.log(this.form)
				// return false
			    const { data } = await CourseAdd(this.form);
			    if (data.code != 200) return this.$message.error(data.data);
				this.$message({
				  message: data.data,
				  type: 'success'
				});
				setTimeout(()=>{
					this.$router.back(-1)
				},500)
				
			    // this.list = data.data.classify_list;
				// this.options=data.data.classify_list;
			},
			// 获取新闻分类列表
			async getlist() {
			    const { data } = await classifyList({});
			    if (data.code != 200) return this.$message.error(data.data);
			    // this.list = data.data.classify_list;
				this.options=data.data.classify_list;
			},
		},
		
	}
</script>	
<style>
	.edit_container .ql-editing{
		left: 0 !important;
	}
	  .avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
</style>